<template>
 <div class="pa-0 ma-0 mt-0 pr-6">
    <v-expansion-panels v-model="panel">
      <hb-expansion-panel v-if="action.count > 0 && filteredActions.length != 0" v-for="(action, actionIndex) in filteredActions" :key="actionIndex" class="mb-2" caution
        :leftCols="'8'" :rightCols="'4'">
        <template v-slot:title>
         <div>{{ warningHeading(action.type) }}</div>
        </template>
        <template v-if="['missing_fees', 'missing_documents', 'missing_delivery_methods'].includes(action.slug)" v-slot:content class="pa-10">
            <div class="pa-6">
              <p v-if="template === 'corporate'">{{ warningSubHeading('corporate',action.slug) }}</p>
              <p v-if="template !== 'corporate'">{{ warningSubHeading('property',action.slug) }}</p>
              <ol :style="{ listStyleType: template === 'corporate' ? 'auto' : 'none', paddingLeft: template === 'corporate' ? 'inherit' : '0' }">
                <li v-for="(detail, detailIndex) in action.grouped" :key="detailIndex">
                  <strong v-if="template === 'corporate'">{{ detail.name }} - {{ detail.number }}</strong>
                    <ul>
                        <li v-for="(actionItem, actionItemIndex) in detail.spaceTypes" :key="actionItemIndex">
                            <strong>{{ formatWorkflowName(actionItem.path) }}</strong>
                            <ul>
                              <li v-for="(timeline, index) in actionItem.timelines" :key="index">
                                {{ timeline.name }}: {{ timeline.allActionItems || 'N/A' }}
                              </li>
                            </ul>
                        </li>
                    </ul>
                  </li>
               </ol>
            </div>
        </template>
        <template v-else v-slot:content class="pa-10">
            <div class="pa-6">
              <div class="pb-2">
                <div>An Anchor Stage limits how far the automation will progress when triggered by payment reversal</div>
                <div>The Following automations are missing anchor stage configuration:</div>
              </div>
              <ol>
                <li v-for="(item, index) in action.details" :key="index">
                <strong class="px-2"> {{ formatWorkflowName(item?.group_name) }} </strong>
                </li>
              </ol>
            </div>
        </template>
      </hb-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script type="text/babel">
import { mapGetters } from "vuex";
import AUTOMATION from '@/constants/automation.js';
import ADDRESS from '@/constants/address.js';
import { countryStateCityMixin } from '@/mixins/countryStateCityMixin.js';
export default {
  name: "AutomationWarnings",
  mixins: [countryStateCityMixin],
  data() {
    return {
      panel: true
    };
  },
  props: {
    template: {
      type: String,
      default() {
        return 'corporate';
      }
    }
  },
  created(){
    this.AUTOMATION = AUTOMATION;
    this.setCountiesAndStates();
  },
  methods: {
    setCountiesAndStates() {
      this.setCountriesListEvent();
      this.setStatesListEvent(ADDRESS.DEFAULT_COUNTRY);
    },
    formatWorkflowName(workflow_full_path) {
      if (workflow_full_path) {
        const parts = workflow_full_path.split(' > ');
        const state = parts.length ? parts[0] : '';
        const formattedState = this.stateListRawMixin.find(item => item.isoCode === state)?.name || state;
        return [formattedState, ...parts.slice(1)].join(' > ');
      }
      return workflow_full_path || '';
    },
    warningSubHeading(context,slug){
      const typeMapping = {
        missing_fees: 'fees',
        missing_documents: 'documents',
        missing_delivery_methods: 'delivery methods',
        missing_anchor_stages: 'missing anchor stage'
      };

      const type = typeMapping[slug] || 'fees';

      const corporateHeadingText = `The following properties do not have access to one or more ${type} that are part of their associated timelines.`;
      const propertyHeadingText = `Property does not have access to one or more ${type} that are part of the associated timelines.`;

      return context === 'corporate' ? corporateHeadingText : propertyHeadingText;

    },
    warningHeading(type){
      return `Warning: ${type || ''}`;
    }
  },
  destroyed(){
    this.$store.commit('automationStore/setMissingActions', []);
  },
  computed: {
    ...mapGetters({ 
      missingActions: "automationStore/getMissingActions"
    }),
    filteredActions(){
      const allMissingActions = [];  

      this.missingActions.forEach(item => {
        const entry = {
          type: item.type,
          slug: item.slug,
          count: item.count,
          details: item.details,
          grouped: []
        };

        if (item.slug === 'missing_fees' || item.slug === 'missing_documents' || item.slug === 'missing_delivery_methods' || item.slug === 'missing_anchor_stages') {
          // Grouping logic for missing actions
          const groupedByProperty = {};
          
          item.details.forEach(actionItem => {
            // Group by property name
            const propertyKey = actionItem.property_name;
            if (!groupedByProperty[propertyKey]) {
              groupedByProperty[propertyKey] = {
                name: actionItem.property_name,
                number: actionItem.property_number,
                spaceTypes: {}
              };
            }

            // Group by full path under the same property
            const pathKey = actionItem.full_path;
            if (!groupedByProperty[propertyKey].spaceTypes[pathKey]) {
              groupedByProperty[propertyKey].spaceTypes[pathKey] = {
                path: pathKey,
                timelines: [] // Initialize timelines as an array
              };
            }

            // Group by space type timeline
            actionItem.actions.forEach(action => {
              const timelineKey = action.space_type_timeline;
              
              // Check if the timeline already exists in the array
              const existingTimeline = groupedByProperty[propertyKey].spaceTypes[pathKey].timelines.find(t => t.timeline === timelineKey);
              
              const actionItemName = action.names.join(', '); // Create a comma-separated string of fees
              
              if (existingTimeline) {
                // Increment the count and append the fee name if the timeline already exists
                existingTimeline.count += 1;
                existingTimeline.actionItems.push(actionItemName); // Append to fees array
              } else {
                // Add new timeline object if it does not exist
                groupedByProperty[propertyKey].spaceTypes[pathKey].timelines.push({
                  timeline: timelineKey,
                  count: 1, // Initialize count for the new timeline
                  actionItems: [actionItemName] // Initialize fees as an array with the current fee name
                });
              }
            });
          });

          // Convert spaceTypes object to an array
          entry.grouped = Object.values(groupedByProperty).map(property => ({
            ...property,
            spaceTypes: Object.values(property.spaceTypes).map(spaceType => ({
              ...spaceType,
              // timelines is already an array, so no need to convert
              timelines: spaceType.timelines.map(t => ({
                name: t.timeline,
                count: t.count,
                allActionItems: t.actionItems.join(', ') // Join fees into a comma-separated string
              }))
            }))
          }));
        }

        // Finally, push it to the main array.  
        allMissingActions.push(entry);
      });
 
       
      console.log("allMissingActions",allMissingActions)
      return allMissingActions;
    }
  }
};
</script>
  